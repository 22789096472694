// @font-face {
//     font-family: "calibri_light";
//     src: url('.//fonts/calibri_light.ttf');
// }


$color-primary: #28A745;
$color-btn: #28A745;
$color-secondary: #ebecf4;
// $color-text: #ebecf4;
$color-text: black;
$color-third: #7a3add;
$color-orange: #FFC107;
$font_calibri_light: 'calibri_light';