@use 'variable.scss' as var;
@use 'mixins.scss' as mixins;
@import 'normalize.css';


@font-face {
    font-family: "calibri_light";
    src: href('../public/fonts/calibri_light.ttf');
}


@font-face {
    font-family: "tele2_displayserif-bold";
    src: href('../public/fonts/tele2_displayserif-bold.ttf');
}

@font-face {
    font-family: "calibri_regular";
    src: href('../public/fonts/calibri.ttf');
}


* {
    padding: 0;
    margin: 0;
}

h1, h2, h3, h4, h5, h6, a, span, p, button, li, input{
    font-family: "calibri_regular";
    color: var.$color-text;
    margin: 0;
    padding: 0;
}

span, p, a{
    font-family: "calibri_light";
}

h1 { @include mixins.fluid-text(40, 28) }
h2 { @include mixins.fluid-text(36, 26) }
h3 { @include mixins.fluid-text(32, 24) }
h4 { @include mixins.fluid-text(28, 22) }
h5 { @include mixins.fluid-text(24, 20) }
h6 { @include mixins.fluid-text(20, 18) }

ul{
    list-style: none;
}

a:active,
a:hover,
a{
    text-decoration: none;
    // color: black;
}

body {
    @include mixins.fluid-text(20, 18);
    // overflow-x: hidden;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // background-color: var.$color-secondary;
    // min-width: mixins.rem(320);
}

.container {
    // width: 100%;
    // max-width: mixins.rem(1300);
    // overflow-x: hidden;
}

  