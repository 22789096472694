.home {
    
  
    h2 {
      color: #333232; // Цвет заголовка аналогичный цветам Telegram
    }
  
    p {
      font-family: Roboto;
      font-weight: 400;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: 0%;
      text-align: center;

    }
  
    .content {
      max-width: 600px; // Ширина контентной части
      text-align: center;
    }

    *{
      margin: auto
    }

    .button {
      width: 80vw;
      height: 8vh;
      background-color: #333232;
      border-radius: 20px;
      font-family: SF Pro;
      font-weight: 590;
      font-size: 17px;
      line-height: 100%;
      letter-spacing: 0%;
      text-align: center;
      color: #FFFFFF
    }
  }
  