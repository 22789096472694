/* styles.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .title {
    margin-bottom: 16px;
    text-align: center;
    word-break: break-word;
  }
  
  .message {
    margin-bottom: 24px;
    text-align: center;
    word-break: break-word;
    display: inline-block;
  }