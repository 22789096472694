* {
    padding: 0;
}

.table-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: calc(50% - 20px);
    height: 25vh;
    margin: 4px;
    transition: transform 0.2s;
    flex: 0 0 calc(50% - 20px);
    background-color: #F4F4F4;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer; 
    &  img {
        // border-radius: 8px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        width: 100%;
        height: 60%;
    }
    // p {
    //     font-size: 1rem;
    // }
}