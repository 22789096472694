@use 'sass:math';

@mixin fluid-text($max: 48, $min: 16) {
  $maxViewportWidth: 1300;
  $minViewportWidth: 360;

  $maxSize: math.div($max, 16);
  $minSize: math.div($min, 16);
  $maxWidth: math.div($maxViewportWidth, 16);
  $minWidth: math.div($minViewportWidth, 16);

  $slope: math.div(($maxSize - $minSize), ($maxWidth - $minWidth));
  $yAxisIntersection: -$minWidth * $slope + $minSize;

  font-size: clamp(
    #{$minSize * 1rem},
    #{$yAxisIntersection * 1rem} + #{$slope * 100vw},
    #{$maxSize * 1rem}
  );
}

@mixin fluid-top($max: 48%, $min: 16%) {
  $maxViewportWidth: 1300px; // Максимальная ширина вьюпорта
  $minViewportWidth: 360px;   // Минимальная ширина вьюпорта

  // Преобразование значений в относительные единицы
  $maxSize: $max / 100 * 1rem; // Преобразовать проценты в ремы
  $minSize: $min / 100 * 1rem; // Преобразовать проценты в ремы
  $maxWidth: $maxViewportWidth / 16; // Переводим в относительные единицы
  $minWidth: $minViewportWidth / 16;

  $slope: ($maxSize - $minSize) / ($maxWidth - $minWidth);
  $yAxisIntersection: -$minWidth * $slope + $minSize;

  // Устанавливаем значение top
  top: clamp(
    #{$minSize},
    #{$yAxisIntersection} + #{$slope * 100vw},
    #{$maxSize}
  );
}

@function rem($pixel) {
  @if math.is-unitless($pixel) {
      @return math.div($pixel, 16) + rem;
  }@else{
      @error "Напиши без едениц измерений";
  }
};

@mixin responsive-width($initialWidth, $finalWidth, $breakpoint) {
  width: $initialWidth; // начальная ширина
  transition: width 0.3s ease; // плавный переход при изменении ширины

  @media (min-width: $breakpoint) {
    &{
      width: $finalWidth;
    }
 
  }
}

// h1 { @include fluid-text(40, 28) }
// h2 { @include fluid-text(36, 26) }
// h3 { @include fluid-text(32, 24) }
// h4 { @include fluid-text(28, 22) }
// h5 { @include fluid-text(24, 20) }
// h6 { @include fluid-text(20, 18) }
// body { @include fluid-text(18, 16) }