// TableSelectionPage.scss

.tableCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 auto;
    margin: auto;
    h2 {
      text-align: center;
      margin: 1vh 0;
    }
  
    p {
      text-align: center;
      margin: 1vh 0;
    }
  
    .table-cards {
      height: 80vh;
      display: flex;
      // padding: 5px;
      flex-wrap: wrap;
      justify-content: space-around; // Чтобы карточки равномерно распределялись
      overflow-y: auto;
      // gap: 5px; // Промежуток между карточками
    }

    .date {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 80vw;
      height: 5vh;
      padding: 10px 4px;
      background-color: #F1F1F1;
      box-shadow: 1px 1px 1px 1px #0000001A;
      border-radius: 20px;
      align-items: center;
      justify-content: space-evenly;
      margin: 1vh 0;
    }
  }
  