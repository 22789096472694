
.container {
    h2 {
        margin: 1vh 0;
    }

    img {
        width: 80%
    }

    h5 {
        margin: 3px 0;
        span{
            font-weight: 400;
        }
    }
    .textarea{
        margin: 1vh 0;
        border: 1px solid black;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        &::placeholder{
            color: black;
        }
    }
}

.line {
    width: 100%;
    height: 1px;
    background-color: #C6C6C6;
    margin: 2vh 0 ;
}

.booking_description {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.short_desccription {
    margin-top: 1vh;
    display: flex;

    & img {
        width: 40%;
        border-radius: 20px;
    }
    div {
        margin-left: 5%;
        p{
            font-size: 1rem;
        }
    }
}

.toggleBtn {
    padding: 6px 12px;
    background-color: #333232;
    color: white;
    border-radius: 2vh;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    min-width: 100px;
    transition: background-color 0.3s;
    user-select: none;
    border: none;
    font-size: 14px;
    font-weight: 500;
  }
  
  .toggleBtn:hover {
    background-color: #333232;
  }
  
  .toggleBtn.added {
    background-color: #4CAF50;
  }
  
  .toggleBtn.added:hover {
    background-color: #45a049;
  }

  .prefer {
    display: flex;
    align-items: center;
    margin-top: 3vw;
    & h5 {
        font-family: Roboto;
        font-weight: 300;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0px;
        vertical-align: middle;

    }
    & img {
        width: 5vh;
        height: 5vh;
    }
    & > div:last-child {
        margin-left: auto;
        font-family: Roboto;
        font-weight: 300;
        font-size: 13px;
        line-height: 19px;
        letter-spacing: 0px;
        vertical-align: middle;

      }
  }

  .button {
    width: 80vw;
    height: 8vh;
    background-color: #333232;
    border-radius: 20px;
    font-family: SF Pro;
    font-weight: 590;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: 0%;
    text-align: center;
    color: #FFFFFF;
    margin: 1vh 0;
  }

  .warning{
    margin: 2vh 0;
    width: 80%;
    font-family: Roboto;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: center;
    vertical-align: middle;
    color: #7F7F7F;
  }